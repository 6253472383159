import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Itamar Mula" />
            <Root>
                <Main>
                    <Greetings>Hi there! I'm Itamar.</Greetings>
                    <Content>
                        a software developer turned entrepreneur.
                     </Content>
                    <SubContent>
                        Currently, I'm working on making software engineering more predictable at{" "}
                        <a href="https://acumen.io">acumen.io</a>.
                    </SubContent>
                    <SubContent>
                        To keep updated with my works, follow me on{" "}
                        <a href="https://www.linkedin.com/in/itamarmula">LinkedIn</a>,{" "}
                        <a href="https://github.com/ItamarM">GitHub</a> or check out my <a href="/blog">blog</a>.
                    </SubContent>
                </Main>
            </Root>
        </Layout>
    );
};

export default IndexPage;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 128px);
`;

const Main = styled.main`
  max-width: 540px;
`;

const Greetings = styled.h1`
  font-size: 3rem;
  margin: 0;
  line-height: 3rem;
  font-weight: 600;

  @media only screen and (max-width: 380px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const Username = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  color: #9b9b9b;
  @media only screen and (max-width: 380px) {
    font-size: 0.8rem;
  }
`;

const Content = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.3rem;
  @media only screen and (max-width: 380px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
`;

const SubContent = styled.h4`
  font-size: 1.3rem;
  font-family: var(--text-font-family);
  font-weight: 300;
  @media only screen and (max-width: 380px) {
    font-size: 1rem;
  }
`;
